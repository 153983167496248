<template>
  <v-card
    width="380"
    :tile="$vuetify.breakpoint.mdAndUp"
    :rounded="$vuetify.breakpoint.smAndDown"
    :class="`${styles} ${planStatusStyle} ${
      $vuetify.breakpoint.smAndDown ? 'mb-5' : ''
    }`"
    :outlined="$vuetify.breakpoint.smAndDown"
    :key="planPrice"
  >
    <!-- Nome do plano -->
    <div
      class="text-md-body-1 font-weight-medium text-capitalize pb-3 d-flex justify-center align-center break-word"
      style="height: 50px"
    >
      {{ isTrial ? "Plano Trial (14 dias)" : paymentSubType }}
    </div>

    <!-- Descrição do plano -->
    <p
      v-if="planDescription.trim()"
      class="px-8 py-4 border-bottom border-top d-flex justify-center align-center break-word"
      style="height: 80px"
    >
      {{ isTrial ? "Teste por 14 dias sem compromisso" : planDescription }}
    </p>

    <!-- Novas livenças (caso do anual) -->
    <div class="pt-2">
      <div class="font-weight-bold accent--text caption mb-2">
        <div class="d-flex align-center justify-center">
          <!-- Mostra currentMaxLicensesNumber se for ANNUAL ou currentLicensesNumber se for mensal-->
          <span>
            Para {{ newLicensesNumber }}
            {{ newLicensesNumber === 1 ? "licença" : "licenças" }}</span
          >

          <v-tooltip top v-if="showLowerLicensesNumberWarning">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                data-testid="minimum-licenses-alert"
                color="error"
                v-bind="attrs"
                v-on="on"
                class="ml-2 pulse"
                v-text="'mdi-alert-circle'"
                size="20"
              />
            </template>
            <span
              >O número de licenças contratadas não pode ser<br />
              inferior ao número de licenças ativas</span
            >
          </v-tooltip>
        </div>

        <!-- Container de edição do número de licenças -->
        <div
          class="d-flex align-center justify-center"
          v-if="isAnnual && !readOnly"
        >
          <v-btn
            data-testid="minus-button-licenses"
            small
            icon
            outlined
            :disabled="
              newLicensesNumber <= minimunLicensesNumber ||
              disableActions ||
              blockInvalidSubscriptionForHiredFlex
            "
            color="accent"
            :class="`mr-2 ml-auto ${
              disableActions ||
              blockInvalidSubscriptionForHiredFlex ||
              isDowngrade
                ? ''
                : 'hover-button'
            }`"
            @click="newLicensesNumber > 1 ? newLicensesNumber-- : ''"
          >
            <v-icon v-text="'mdi-minus'" size="" />
          </v-btn>
          <v-text-field
            data-testid="input-licenses-number"
            class="licenses py-2 text-center"
            v-model="newLicensesNumber"
            @change="setMinimunLicenses"
            :min="minimunLicensesNumber"
            :rules="[rules.maxMin]"
            :disabled="
              disableActions ||
              blockInvalidSubscriptionForHiredFlex ||
              isDowngrade
            "
            type="number"
            style="max-width: 70px; text-align: center"
            title="Defina um número máximo de licenças caso acredite que precisará adicionar novos usuários depois do contrato"
          ></v-text-field>
          <v-btn
            small
            icon
            outlined
            color="accent"
            :class="`mr-2 mr-auto ${
              disableActions ||
              blockInvalidSubscriptionForHiredFlex ||
              isDowngrade
                ? ''
                : 'hover-button'
            }`"
            @click="newLicensesNumber++"
            :disabled="
              disableActions ||
              blockInvalidSubscriptionForHiredFlex ||
              isDowngrade
            "
          >
            <v-icon v-text="'mdi-plus'" size="" />
          </v-btn>
        </div>
      </div>

      <!-- Lista de produtos -->
      <div class="mb-5" v-if="readOnly">
        <div class="font-weight-bold">Inclui:</div>
        <div
          v-for="(item, i) in selectedPlanProducts"
          :key="i"
          class="primary--text font-weight-medium text-md-body-2"
        >
          <span>{{ productNames[item] }}</span>
        </div>
      </div>

      <!-- PREÇOS -->
      <div v-if="showPriceToClient" :class="`${readOnly ? 'mt-6' : ''} mb-6`">
        <!-- Mensagem com o preço por licença -->
        <div
          class="my-auto justify-center d-flex align-center font-weight-bold black--text text-lg-h5"
        >
          <span v-if="isTrial" class="text-uppercase"> Grátis </span>

          <span v-else-if="readOnly" class="d-flex align-center">
            {{
              formatMoney(
                getPriceByPeriod(subscriptionType, planPrice, discount)
              )
            }}
            <span
              class="ml-1 caption grey--text text--darken-1 font-weight-medium"
            >
            </span>
          </span>

          <span v-else class="d-flex align-center">
            {{ formatMoney(priceByUserAndPeriod) }}
            <span
              class="ml-1 caption grey--text text--darken-1 font-weight-medium"
            >
              {{ "usuário/" + translatePeriods[subscriptionType] }}
            </span>
          </span>
        </div>

        <!-- Mensagem com o preço total -->
        <div
          v-if="newLicensesNumber && !isTrial"
          class="font-weight-bold grey--text text--darken-2 caption"
        >
          {{ totalPriceByPeriod }}
        </div>

        <!-- Mensagem de destaque do desconto obtido naquele plano -->
        <div
          v-if="showDiscountText"
          class="caption font-weight-medium light-green--text text--darken-2 pb-2 d-flex align-center justify-center"
        >
          Desconto de {{ discountText }}% do valor original
        </div>
        <v-btn
          v-if="showDiscountEditButton"
          class="text-none text-decoration-underline caption font-weight-medium elevation-0"
          text
          x-small
          color="accent"
          @click="showEditDiscount = true"
        >
          Editar
        </v-btn>
      </div>
    </div>

    <!-- Botão para contratar plano -->
    <v-btn
      v-if="!readOnly"
      color="accent"
      elevation="0"
      :class="`
        mt-auto
        self-align-end
        py-2
        mb-4
        mx-auto
        font-capitalize
        ${
          disableActions ||
          disabledSelect ||
          blockInvalidSubscriptionForHiredFlex ||
          blockAnnualToFlexPurchase ||
          isDowngrade
            ? ''
            : 'hover-button'
        }
        text-none
        d-flex
        align-center
      `"
      max-width="200"
      :disabled="
        newLicensesNumber < minimunLicensesNumber ||
        disableActions ||
        disabledSelect ||
        blockInvalidSubscriptionForHiredFlex ||
        blockAnnualToFlexPurchase ||
        isDowngrade
      "
      @click="selectSubscriptionTypePlan"
      data-testid="button-select-plan"
    >
      Selecionar
    </v-btn>

    <DiscountDialog
      v-if="!isSelfCheckout"
      :show="showEditDiscount"
      :customer-name="selectedResellerClient?.name"
      :discount="newDiscount"
      :subscription-type="subscriptionType"
      :licenses="minimunLicensesNumber"
      :max-licenses="newLicensesNumber"
      :plan-price="planPrice"
      :plan-name="currentSelectedPlan.name"
      @update="updatePriceAndDiscount"
      @close="showEditDiscount = false"
    />
  </v-card>
</template>
<script>
import DiscountDialog from "@/components/reseller/DiscountDialog.vue";

import {
  getPriceByPeriod,
  getTotalPlanPrice,
} from "@/helpers/services/billing";
import { hasDecimal, formatMoney } from "@/helpers/services/utils";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import { subscriptionPlans } from "@/helpers/variables/subscriptionPlans.js";
import {
  monthsByPeriod,
  productNames,
  translatePeriods,
} from "@/helpers/variables/translateString";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PurchasePlanCard",

  props: {
    id: { type: String, default: "" },
    subscriptionType: { type: String, default: "" },
    styles: { type: String, default: "" },
    readOnly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    resellerInfo: { type: Object, default: () => {} },
    impactedAnnualUpgrade: { type: Array, required: false },
    disableActions: { type: Boolean, default: false },
    disabledSelect: { type: Boolean, default: false },
  },

  components: { DiscountDialog },

  data() {
    return {
      STATUS,
      SUBSCRIPTIONS,
      subscriptionPlans,
      translatePeriods,
      monthsByPeriod,
      rules: {
        maxMin: (value) =>
          parseFloat(value) >= this.minimunLicensesNumber ||
          `Mínimo ${this.minimunLicensesNumber}`,
      },
      newLicensesNumber: 0,
      productNames,
      showEditDiscount: false,
      discount: 0,
      newDiscount: 0,
      newPrice: 0,
    };
  },

  computed: {
    ...mapGetters([
      "isConecta",
      "hasIntermediaryPartner",
      "selectedResellerClient",
      "impactedCompanyPlans",
      "clientHasThisPlan",
      "currentSelectedPlan",
      "selectedPlanKey",
      "selectedPlanProducts",
      "company",
      "isSelfCheckout",
      "usersNumber",
    ]),

    selectedPlanRank() {
      return this.currentSelectedPlan.rank;
    },

    /* VALIDA SE TA INDO DE UM ANUAL PARA UM MENSAL */
    blockInvalidSubscriptionForHiredFlex() {
      if (!this.isSelfCheckout) {
        return false;
      }

      if (this.clientHasThisPlan) {
        const { subscription_type, status } = this.clientHasThisPlan;
        const hiredIsFlex =
          subscription_type == SUBSCRIPTIONS.FLEX && status == STATUS.ACTIVE;

        if (hiredIsFlex) {
          return !(subscription_type == SUBSCRIPTIONS.FLEX && this.isAnnual);
        }
        return false; // Não pode bloquear se o subscription_type é FLEX porém o status é TRIAL por exemplo
      }

      return false;
    },

    blockAnnualToFlexPurchase() {
      if (!this.isSelfCheckout) {
        return false;
      }
      const annualActivePlans = this.impactedCompanyPlans.filter(
        (companyPlan) =>
          companyPlan.status === STATUS.ACTIVE &&
          companyPlan.subscription_type == SUBSCRIPTIONS.ANNUAL
      );

      if (annualActivePlans.length && this.isFlex) {
        return true;
      }

      return false;
    },

    isDowngrade() {
      if (!this.isSelfCheckout) {
        return false;
      }

      const annualActivePlans = this.impactedCompanyPlans.filter(
        (companyPlan) =>
          companyPlan.status === STATUS.ACTIVE &&
          companyPlan.subscription_type == SUBSCRIPTIONS.ANNUAL
      );

      let downgrade = false;

      annualActivePlans.some((annual_plan) => {
        if (this.selectedPlanRank <= annual_plan.plan.rank) {
          downgrade = true;
        }
      });

      return downgrade && this.isAnnual;
    },

    showLowerLicensesNumberWarning() {
      if (this.isAnnual) {
        return this.currentLicensesNumber < this.minimunLicensesNumber;
      }
      return false;
    },

    paymentSubType() {
      return this.subscriptionType
        ? subscriptionPlans[this.subscriptionType].title
        : "";
    },

    planDescription() {
      return this.subscriptionType
        ? subscriptionPlans[this.subscriptionType].description
        : "";
    },

    currentLicensesNumber() {
      // Se for a empresa do usuário logado, retorna o número de usuários da mesma
      if (this.isSelfCheckout) {
        return this.usersNumber;
      }
      return this.selectedResellerClient.users_number || 0;
    },

    minimunLicensesNumber() {
      // Se for a empresa do usuário logado, retorna o número de usuários da mesma
      if (this.isSelfCheckout) {
        return this.usersNumber;
      }

      return this.selectedResellerClient.users_number || 0;
    },

    planPrice() {
      if (this.clientHasThisPlan) {
        return parseFloat(this.clientHasThisPlan.plan.price);
      }
      return parseFloat(this.currentSelectedPlan.price);
    },

    isTrial() {
      return this.subscriptionType === SUBSCRIPTIONS.TRIAL;
    },

    isAnnual() {
      return this.subscriptionType === SUBSCRIPTIONS.ANNUAL;
    },

    isFlex() {
      return this.subscriptionType === SUBSCRIPTIONS.FLEX;
    },

    showDiscountEditButton() {
      return (
        this.isConecta &&
        !this.readOnly &&
        this.planPrice !== 0 &&
        !this.isSelfCheckout &&
        !this.isTrial
      );
    },

    showDiscountText() {
      return (
        this.newDiscount > 0 &&
        this.planPrice &&
        this.discountText &&
        !this.isTrial
      );
    },

    planStatusStyle() {
      const generalClasses = `d-flex flex-column elevation-0 text-center pb-6`;

      return `${generalClasses} pt-10 normal-border ${
        !this.isAnnual && this.$vuetify.breakpoint.mdAndUp
          ? "purchased-items"
          : "purchased-mobile-items"
      } ${
        !this.isAnnual && this.$vuetify.breakpoint.mdAndUp ? "border-right" : ""
      }`;
    },

    partnerDiscount() {
      if (this.resellerInfo) {
        return parseFloat(this.resellerInfo.discount);
      }
      return 0;
    },

    /**
     * Variable to calculate the preview price when we are changing the licenses or the discount
     * @return {Number} The new plan price
     */
    previewPrice() {
      // return this.planPrice * (1 - this.newDiscount / 100);
      return getTotalPlanPrice(
        this.currentLicensesNumber,
        this.subscriptionType,
        this.newDiscount / 100,
        this.planPrice
      );
    },
    invalidDiscount() {
      if (this.newDiscount == "") {
        return true;
      } else if (
        this.newDiscount >= 0 &&
        this.newDiscount <= this.maxDiscount
      ) {
        return false;
      }
      return true;
    },

    discountText() {
      let discount = parseFloat(this.newDiscount) || 0;

      if (discount > 0) {
        if (hasDecimal(discount)) {
          return `~${discount.toFixed(2)}`;
        }
        return `${parseInt(discount)}`;
      }
      return "";
    },

    /**
     * Price calculated based on discount
     * @return {Number}
     */
    priceByUserAndPeriod() {
      const discount = this.newDiscount / 100;
      return getPriceByPeriod(this.subscriptionType, this.planPrice, discount);
    },

    showPriceToClient() {
      return !this.isSelfCheckout || !this.hasIntermediaryPartner;
    },

    /**
     * The price and subscription type to display
     * @return {String} In the format price/subscription type. Eg.: (R$2,00/ano)
     */
    totalPriceByPeriod() {
      return `(${formatMoney(this.totalPrice)}/${
        translatePeriods[this.subscriptionType]
      })`;
    },

    /**
     * The final total price of the plan
     * @return {Number}
     */
    totalPrice() {
      const discount = this.newDiscount > 0 ? this.newDiscount / 100 : 0;

      const total = getTotalPlanPrice(
        this.newLicensesNumber,
        this.subscriptionType,
        discount,
        this.planPrice
      );

      return total;
    },
  },

  watch: {
    async "$route.query"() {
      await this.setUsersNumber();
      this.sendInitialPlanValue();
    },

    selectedPlanKey() {
      if (this.selectedPlanKey) {
        this.sendInitialPlanValue();
        this.resetDiscountInfo();
      }
    },

    planPrice() {
      this.emitPlanDetails();
    },

    newLicensesNumber() {
      this.emitPlanDetails();
    },

    async currentLicensesNumber() {
      await this.setUsersNumber();
    },

    newPrice() {
      if (this.showEditDiscount) {
        setTimeout(this.setNewDiscount, 2000);
      } else {
        this.setNewDiscount();
      }
    },

    async selectedResellerClient() {
      if (this.selectedResellerClient) {
        await this.setUsersNumber();
      }
    },

    async "$route.path"() {
      await this.setUsersNumber();
    },
  },

  methods: {
    formatMoney,

    getTotalPlanPrice,

    getPriceByPeriod,

    ...mapMutations(["updatePlanInCart"]),

    updatePriceAndDiscount(update) {
      const { price, discount } = update;

      this.newPrice = price;
      this.newDiscount = discount;
    },

    sendInitialPlanValue() {
      this.emitPlanDetails();
    },

    setNewDiscount() {
      this.newDiscount = parseFloat(
        ((this.planPrice - this.newPrice) * 100) / this.planPrice
      ).toFixed(2);
      this.updatePlanInCart({
        key: "discount",
        value: this.newDiscount / 100,
      });
    },

    resetDiscountInfo() {
      if (this.isAnnual) {
        this.newDiscount =
          (1 -
            (1 - 2 / monthsByPeriod[this.subscriptionType]) *
              (1 - this.partnerDiscount)) *
          100;
      } else if (this.isFlex) {
        this.newDiscount = this.partnerDiscount * 100;
      }

      this.discount = this.newDiscount / 100;
      this.newPrice = this.planPrice * (1 - this.discount);
    },

    async setMinimunLicenses(value) {
      if (value < this.minimunLicensesNumber) {
        await this.setUsersNumber();
      }
    },

    setUsersNumber() {
      this.newLicensesNumber = this.currentLicensesNumber;
    },

    selectSubscriptionTypePlan() {
      let infoPlan = {};

      if (this.clientHasThisPlan) {
        infoPlan = Object.assign({}, this.clientHasThisPlan);
        infoPlan.name = this.currentSelectedPlan.name;
        infoPlan.notes = this.currentSelectedPlan.notes;
      } else {
        const { discount, name, price, products } = this.currentSelectedPlan;
        infoPlan = {
          discount,
          plan_key: this.selectedPlanKey,
          name,
          price,
          products,
          notes: "",
        };
      }

      infoPlan.price = this.planPrice;
      infoPlan.licenses = this.currentLicensesNumber;
      infoPlan.max_licenses = this.isAnnual
        ? parseInt(this.newLicensesNumber)
        : null;

      infoPlan.discount = this.newDiscount / 100;
      infoPlan.discountText = this.discountText;

      infoPlan.subscription_type = this.subscriptionType;
      infoPlan.status = subscriptionPlans[this.subscriptionType].status;

      infoPlan.products = this.selectedPlanProducts;
      infoPlan.total = this.isTrial ? 0 : this.totalPrice;

      this.$emit("selected", infoPlan);
    },

    editDiscount() {
      this.discount = this.newDiscount / 100 || 0;
      this.showEditDiscount = false;
      this.emitPlanDetails();
    },

    emitPlanDetails() {
      if (this.selectedPlanKey && !this.isTrial) {
        this.$emit("update-plan", {
          licenses: this.newLicensesNumber,
          discount: this.newDiscount / 100,
          price: this.planPrice,
          subscription_type: this.subscriptionType,
        });
      }
    },
  },

  async beforeMount() {
    await this.setUsersNumber();
  },

  mounted() {
    this.sendInitialPlanValue();
    this.resetDiscountInfo();
  },

  beforeUnmount() {
    this.resetDiscountInfo();
    this.newLicensesNumber = this.currentLicensesNumber;
  },
};
</script>
<style>
.purchased-items {
  box-sizing: border-box !important;
}
.purchased-items:hover {
  background: #e8f0fe !important;
}

.border-right {
  border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 0px !important;
}
.purchased-items button {
  text-transform: none !important;
}

.disabled-plan {
  background: #fafafa !important;
}

.purchased-items:hover .hover-button,
.purchased-items-no-bg-hover:hover .hover-button {
  background: var(--v-accent-base);
  color: #ffffff !important;
}
.purchased-items-button:hover,
.purchased-items-no-bg-hover .purchased-items-button:hover {
  color: #fff !important;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
.border-top {
  border-top: 1px solid #ccc;
}
.promo-caroulsel .v-window__next,
.v-window__prev {
  background: none !important;
  top: 0px !important;
}
.promo-caroulsel .v-btn--outlined .v-btn__content .v-icon,
.promo-caroulsel .v-btn--round .v-btn__content .v-icon,
.promo-caroulsel .v-window__next button,
.promo-caroulsel .v-window__prev button {
  color: var(--v-accent-base) !important;
  background: transparent !important;
}
.normal-border {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
@media (max-width: 768px) {
  .normal-border {
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
}
.currently-plan {
  border: 1px solid var(--v-success-base) !important;
  border-right: 1px solid var(--v-success-base) !important;
  border-radius: 8px !important;
  margin: 0px;
}
@media (max-width: 763px) {
  .currently-plan {
    border: 1px solid var(--v-success-base) !important;
    border-right: 1px solid var(--v-success-base) !important;
    border-radius: 10px !important;
    margin: 0px 0px 10px 0px !important;
  }
}
.price-edit {
  background: transparent !important;
}
.v-text-field .licenses {
  width: 100px;
}
.licenses input {
  text-align: center !important;
}

.pulse {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: (--v-error-base);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(229, 57, 53, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(2229, 57, 53, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(183, 28, 28, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(229, 57, 53, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(229, 57, 53, 0.4);
    box-shadow: 0 0 0 0 rgba(229, 57, 53, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
</style>
