var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{class:`${_vm.$vuetify.breakpoint.lgAndUp ? 'px-6' : 'px-4'} py-6`},[_c('v-card',{staticClass:"fill-height mx-auto elevation-0 d-flex flex-column",attrs:{"outlined":"","width":"100vw","height":"88vh"}},[_c('v-row',{staticClass:"px-5 py-2 ma-0 d-flex align-center",staticStyle:{"border-bottom":"1px solid rgba(0, 0, 0, 0.12)"},attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"breadcrumb-link",attrs:{"icon":""},on:{"click":_vm.goBack}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-arrow-left')}})],1),(_vm.loading)?_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"max-width":"500"}},_vm._l((3),function(i){return _c('v-col',{key:i,staticClass:"ma-0 pa-0 d-flex align-center"},[_c('v-skeleton-loader',{attrs:{"type":"chip"}}),(i < 3)?_c('v-icon',{domProps:{"textContent":_vm._s('mdi-chevron-right')}}):_vm._e()],1)}),1):_c('v-breadcrumbs',{staticClass:"pa-0 py-1 mx-3 breadcrumbs breadcrumb-link",attrs:{"items":_vm.breadcrumbs,"large":""},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',{domProps:{"textContent":_vm._s('mdi-chevron-right')}})]},proxy:true},{key:"item",fn:function({ item }){return [(item.text === 'Comprar' && _vm.selectedPlan)?_c('v-breadcrumbs-item',{attrs:{"disabled":item.disabled,"exact":_vm.steps > 1},on:{"click":_vm.goBackToPlans}},[_c('span',{class:`${
                  item.text === 'Comprar' && _vm.selectedPlan ? 'go-to-step' : ''
                }`},[_vm._v(_vm._s(item.text))])]):_c('v-breadcrumbs-item',{attrs:{"to":item.to,"disabled":item.disabled}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1)]),(_vm.resellerClientDoNotHaveCnpj)?_c('Alert',{staticClass:"mx-4 mt-4",attrs:{"color":"error","text":false,"dark":""}},[_vm._v(" Esta empresa "),_c('b',[_vm._v("não possui CNPJ cadastrado")]),_vm._v(". Por favor, após selecionar o plano, preencha o CNPJ onde for requisitado. ")]):_vm._e(),_c('div',{staticClass:"overflow-y-auto fill-height"},[_c('SimpleDialog',{attrs:{"id":"discount_info","title":"Descontos para Conecta Partners","show":_vm.showDiscountInfoDialog,"readOnly":""},on:{"close":function($event){_vm.showDiscountInfoDialog = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"mt-4 mb-0 pb-0"},[_vm._v(" A partir de 21/11/2021, "),_c('span',{staticClass:"font-weight-medium"},[_vm._v("todos os Conecta Partners independente do tipo de parceria")]),_vm._v(" passam a possuir 20% de desconto nos planos ofertados por eles, isto é, todos os clientes destes parceiros receberão por padrão,"),(_vm.isConecta)?_c('span',[_vm._v(" pelo menos")]):_vm._e(),_vm._v(" 20% de desconto no preço final dos planos"),(_vm.isConecta)?_c('span',[_vm._v(", podendo este desconto ser ampliado com um acréscimo de no máximo, 35% a mais de desconto")]):_vm._e(),_vm._v(". ")])]},proxy:true}])}),_c('div',{staticClass:"my-6 mr-4 mt-0"},[_c('v-stepper',{class:`${
            _vm.$vuetify.breakpoint.mobile ? 'px-0' : ''
          } elevation-0 justify-center d-block py-0`,attrs:{"flat":""},model:{value:(_vm.steps),callback:function ($$v) {_vm.steps=$$v},expression:"steps"}},[_c('v-stepper-header',{staticClass:"steps elevation-0 mx-auto font-weight-medium"},[_c('v-stepper-step',{class:{ 'go-to-step': _vm.steps > 1 },attrs:{"step":"1","complete":_vm.steps > 1},on:{"click":_vm.goBackToPlans}},[_vm._v(" Selecionar SKU qualificada ")]),_c('v-divider'),_c('v-stepper-step',{class:{ 'go-to-step': _vm.steps === 3 },attrs:{"step":"2","complete":_vm.steps > 2},on:{"click":function($event){return _vm.goToStep(2)}}},[_vm._v(" Selecionar plano de pagamento ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"3","complete":_vm.steps > 3}},[_vm._v(" Configurações do pedido ")])],1),_c('v-stepper-items',{staticClass:"pt-0"},[_c('v-stepper-content',{staticClass:"pt-1 text-center",attrs:{"step":"1"}},[_c('PlanSelector',{on:{"selected":_vm.selectPlan}})],1),_c('v-stepper-content',{staticClass:"pt-1",attrs:{"step":"2"}},[(_vm.currentClient && _vm.steps === 2)?_c('SubscriptionPlans',{attrs:{"planId":""},on:{"select":_vm.selectSubscriptionType}}):_vm._e()],1),_c('v-stepper-content',{staticClass:"mt-0",attrs:{"step":"3"}},[_c('v-card',{attrs:{"tile":"","flat":"","disabled":_vm.loadingPurchase}},[_c('CompanyInfo'),_c('OrderSettings',{attrs:{"disabled":_vm.loadingPurchase},on:{"end-trial":function($event){return _vm.updatePlanInCart({ key: 'end_trial', value: $event })},"init-transaction":function($event){return _vm.updatePlanInCart({
                      key: 'init_transaction',
                      value: $event,
                    })},"end-transaction":function($event){return _vm.updatePlanInCart({
                      key: 'end_transaction',
                      value: $event,
                    })},"renewal-date":function($event){return _vm.updatePlanInCart({
                      key: 'renewal_date',
                      value: $event,
                    })}}}),_c('CheckoutItems'),(_vm.selectedIsAnnual && _vm.isConecta)?_c('v-card',{staticClass:"py-2 px-0 mt-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pt-2 text-body-1 font-weight-medium grey--text text--darken-3",domProps:{"textContent":_vm._s('Configurações do pedido')}}),_c('v-divider'),_c('v-card-actions',[_c('v-row',[_c('v-col',{staticClass:"pb-1"},[_c('v-row',{staticClass:"mx-0 mt-2 mb-0"},[_c('v-col',{staticClass:"py-0 pb-2 d-flex align-center"},[_c('span',{staticClass:"font-weight-medium mr-4"},[_vm._v("Gerar nova transação para esta edição? ")]),_c('v-switch',{staticClass:"my-0 py-0",staticStyle:{"max-height":"30px"},attrs:{"inset":"","label":_vm.createTransaction ? 'SIM' : 'NÃO'},model:{value:(_vm.createTransaction),callback:function ($$v) {_vm.createTransaction=$$v},expression:"createTransaction"}})],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-scroll-y-reverse-transition',[(_vm.steps > 2)?_c('CheckoutFooter',{attrs:{"loading":_vm.loadingPurchase,"disabledAction":_vm.canNotAddPlan ||
          _vm.minValueNotReached ||
          !_vm.minLicensesNumberUnreached ||
          (!_vm.selectedCNPJ && !(_vm.isConecta && _vm.isEditingTrial))},on:{"purchase":_vm.verifyContractedProducts,"cancel":function($event){_vm.steps = 2}}}):_vm._e()],1)],1),(_vm.impactedCompanyPlans)?_c('HeaderDialog',{attrs:{"subtitle":"Confirmar novo plano","title":_vm.selectedPlanName,"width":"800","show":_vm.showConfirmNewPlan,"loading":_vm.loadingPurchase,"action-text":"Contratar plano","close-text":"Cancelar"},on:{"action":_vm.hirePlan,"close":function($event){_vm.showConfirmNewPlan = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('p',{staticClass:"ma-0"},[_vm._v(" A empresa "),_c('b',[_vm._v(_vm._s(_vm.companyName))]),_vm._v(" já possui um ou mais produtos do plano que você deseja adquirir. Ao prosseguir, você concorda que o plano atual seja desativado e o novo contratado. ")]),_c('v-card-text',{staticClass:"px-center pt-0"},[_c('v-row',{staticClass:"align-center ma-0 pa-0 pb-8",attrs:{"no-gutters":"","dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 5}},[_c('ComparativePlan')],1),(!_vm.$vuetify.breakpoint.xs)?_c('v-col',{staticClass:"pa-0 d-flex justify-center",attrs:{"cols":2}},[_c('v-icon',{staticClass:"mx-auto",attrs:{"size":_vm.$vuetify.breakpoint.xs ? 35 : 65,"right":""},domProps:{"textContent":_vm._s(
                _vm.$vuetify.breakpoint.xs
                  ? 'mdi-arrow-down-thick'
                  : 'mdi-arrow-right-thick'
              )}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.xs ? 12 : 5}},[(_vm.hasPlanInCart)?_c('ComparativePlan',{attrs:{"new":_vm.planInCart,"nextBilling":_vm.planInCart.init_billing}}):_vm._e()],1)],1)],1)]},proxy:true}],null,false,4205920556)}):_vm._e(),_c('Snackbar',{attrs:{"show":_vm.showSnackBarError,"color":"error","message":_vm.error_message,"timeout":"6000"},on:{"close":function($event){_vm.showSnackBarError = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }