<template>
  <v-card outlined>
    <v-data-table
      :headers="headers"
      :items="orders"
      hide-default-footer
      disable-sort
      tile
      class="elevation-0 mb-2"
      no-data-text="Nenhum plano selecionado"
      mobile-breakpoint="900"
    >
      <!-- NOME DO PLANO/SERVIÇO -->
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>

      <template v-slot:[`item.products`]>
        <ProductPlanIcons :plan-slug="slug" mobile-breakpoint />
      </template>

      <template v-slot:[`item.subscription_type`]="{ item }">
        {{
          `${trialSelected ? "" : "Pagamento"} ${
            planPeriodicDescription[item.subscription_type]
          }`
        }}
      </template>

      <!-- COLUNA DE PREÇO -->
      <template v-slot:[`item.price`]>
        <span class="text-right">
          {{ priceByLicense }}
        </span>
      </template>

      <!-- COLUNA DE LICENÇAS -->
      <template v-slot:[`item.licenses`]="{ item }">
        <ButtonNumberIncrementer
          v-if="annualSelected"
          :value="currentLicensesInCart"
          :placeholder="'Nº de licenças'"
          :min="licenses"
          @update="updateLicensesNumber"
        />
        <span v-else>{{ item.licenses }}</span>
      </template>

      <!-- COLUNA DE DESCONTO -->
      <template v-slot:[`item.discount`]="{ item }">
        {{
          item.discount
            ? formatDiscount(item.discount)
            : "Nenhum desconto aplicado"
        }}
      </template>

      <!-- COLUNA DE PREÇO TOTAL -->
      <template v-slot:[`item.total_price`]>
        {{ formatMoney(total) }}
      </template>

      <!-- COLUNA DE PLANO DE PAGAMENTO -->
      <template v-slot:[`item.type`]="{ item }">
        {{
          item.type === STATUS.TRIAL ? STATUS.TRIAL : planPeriodic[item.type]
        }}
      </template>

      <template v-slot:[`item.period`]="{ item }">
        <span class="text-capitalize">
          {{ planPeriodic[item.subscription_type] }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import ButtonNumberIncrementer from "@/components/base/ButtonNumberIncrementer.vue";
import ProductPlanIcons from "@/components/general/ProductPlanIcons.vue";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import {
  planPeriodic,
  planPeriodicDescription,
} from "@/helpers/variables/translateString";
import { formatMoney, formatDate } from "@/helpers/services/utils";
import {
  getTotalPlanPrice,
  getPriceByPeriod,
} from "@/helpers/services/billing";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CheckoutPlans",

  components: { ButtonNumberIncrementer, ProductPlanIcons },

  data() {
    return {
      STATUS,
      SUBSCRIPTIONS,
      planPeriodic,
      planPeriodicDescription,
    };
  },

  computed: {
    ...mapGetters([
      "planInCart",
      "currentLicensesInCart",
      "selectedResellerClient",
    ]),

    orders() {
      return [this.planInCart];
    },

    total() {
      return this.planInCart
        ? getTotalPlanPrice(
            this.currentLicensesInCart,
            this.planInCart.subscription_type,
            this.planInCart.discount,
            this.planInCart.price
          )
        : 0;
    },

    licenses() {
      return this.selectedResellerClient
        ? this.selectedResellerClient.users_number
        : 0;
    },

    annualSelected() {
      return this.planInCart.subscription_type === SUBSCRIPTIONS.ANNUAL;
    },

    trialSelected() {
      return this.planInCart.subscription_type === SUBSCRIPTIONS.TRIAL;
    },

    headers() {
      return [
        { text: "Plano", value: "name", minWidth: 100, show: true },
        { text: "Produtos", value: "products", align: "start", show: true },
        { text: "Plano de pagamento", value: "subscription_type", show: true },
        {
          text: "Licenças",
          value: "licenses",
          align: this.annualSelected ? "start" : "end",
          show: true,
        },
        {
          text: "Custo usuário/mês (R$)",
          align: "end",
          value: "price",
          show: true,
        },
        { text: "Desconto", value: "discount", align: "end", show: true },
        {
          text: "Custo total (R$)",
          value: "total_price",
          align: "end",
          show: true,
        },
        { text: "Período", value: "period", align: "end", show: true },
      ];
    },

    slug() {
      if (this.planInCart) {
        let name = this.planInCart.name;
        return name
          ? name.replace(" - Beta", "").toLowerCase().split(" ").join("_")
          : "";
      }
      return "";
    },

    priceByLicense() {
      const price = getPriceByPeriod(
        this.planInCart.subscription_type,
        this.planInCart.price,
        this.planInCart.discount
      );
      return price > 0 ? formatMoney(price) : "Gratuito";
    },
  },

  methods: {
    ...mapMutations(["updatePlanInCart"]),

    formatMoney,

    formatDate,

    getTotalPlanPrice,

    getPriceByPeriod,

    formatDiscount(discount) {
      const realDiscount = discount ? discount * 100 : 0;
      if (discount === 0) {
        return "Nenhum desconto";
      } else {
        return `${realDiscount.toFixed(2)}%`;
      }
    },

    updateLicensesNumber(number) {
      this.updatePlanInCart({ key: "max_licenses", value: number });
      this.$emit("update", number);
    },
  },
};
</script>
